import React from "react";
import { useDispatch } from "react-redux";
import { setHuddleDay } from "../../reducers/huddleSlice";
import { Button } from "../buttons/button";
import { getFormattedDate, getHuddleTimeDisplay } from "@/app/_utils/formatter";
import { HuddleGroup } from "@/app/_types";
import Image from "next/image";

interface JoinHuddleItemProps {
  huddleItem: HuddleGroup;
  onJoin: () => void;
}

const JoinHuddleItem: React.FC<JoinHuddleItemProps> = ({
  huddleItem,
  onJoin,
}) => {
  const dispatch = useDispatch();
  const localHuddleTime = new Date(huddleItem.localHuddleTime);
  const timeDisplay = getHuddleTimeDisplay(huddleItem);

  const handleJoin = () => {
    const formattedHuddleDay = `${localHuddleTime.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })} @ ${timeDisplay}`;
    dispatch(setHuddleDay(formattedHuddleDay));
    onJoin();
  };

  return (
    <div className="text-center pt-4">
      <div className="text-xl font-semibold">{huddleItem.Location}</div>
      <div>{getFormattedDate(localHuddleTime)}</div>
      <div className="block text-red-secondary font-display uppercase font-semibold text-lg mt-4 py-2">
        Today’s Topic
      </div>
      <div className="text-sm mb-4">{huddleItem.ArticleTitle}</div>
      {huddleItem.UserJoined ? (
        <div className="text-sm flex justify-center items-center gap-1.5">
          <Image
            width={24}
            height={19}
            alt=""
            src="/assets/images/icons/check.svg"
          />
          <span>Joined</span>
        </div>
      ) : (
        <Button onClick={handleJoin}>Join Huddle</Button>
      )}
    </div>
  );
};

export default JoinHuddleItem;
